import React from "react";
import { AiOutlineEye, AiFillStar } from "react-icons/ai";
import { FiTarget } from "react-icons/fi";

import {
  Container,
  TitleContainer,
  MainTitle,
  Grid,
  Item,
  Title,
  Description,
} from "./styles";

function NossosValores() {
  return (
    <Container>
      <TitleContainer>
        <MainTitle>Nossos Valores</MainTitle>
      </TitleContainer>
      <Grid>
        <Item>
          <AiOutlineEye size={90} color="#c90225" />
          <Title>Nossa Visão</Title>
          <Description>
            Com a diminuição da oferta, e com o aumento do desemprego em nosso
            país, fica cada vez mais difícil se conseguir um bom emprego.
            Profissionais parados e que vivem uma inercia profissional, sem
            buscar ou sem investir em qualificação e atualização em sua classe
            ou em novas classes profissionais. ​ Este é, sem dúvidas, um dos
            principais problemas hoje, que resultam no aumento incontrolável de
            pessoas sem qualquer condição de inclusão profissional, nas empresas
            que exigem cada vez mais dos seus funcionários.
          </Description>
        </Item>
        <Item>
          <FiTarget size={90} color="#c90225" />
          <Title>Nossa Missão</Title>
          <Description>
            Prestar um serviço de excelência, na qualificação profissional das
            pessoas e grupos que participam das nossas aulas, ou que contratam
            os serviços da nossa empresa e que necessitam de atualização
            constantes nas mais diversas áreas. Contribuindo para a evolução do
            pensamento administrativo e para a formação de grandes equipes. ​
            Formar profissionais cada vez mais empenhados em atender os anseios
            das empresas, cada dia mais exigentes, e necessitadas de
            colaboradores que invistam em seus sonhos de crescimentos
            empresarial e financeiro.
          </Description>
        </Item>
        <Item>
          <AiFillStar size={90} color="#c90225" />
          <Title>Nossa Filosofia</Title>
          <Description>
            Capacitar, conscientizar e valorizar a vida humana é o que nos faz
            tão decididos e empenhados em prestar um bom serviço para nossos
            clientes e amigos. Todo o ser humano precisa conhecer sua capacidade
            e entender a sua importância na sociedade. A formação deste
            conceito, na mente do indivíduo, é essencial e necessária. ​ Com
            essa filosofia, trabalhamos com todo o amor e firmamos parcerias
            sociais que atendem e contemplam comunidades e famílias carentes da
            nossa cidade e das regiões por onde passamos com nossas atividades
            sociais e profissionais.
          </Description>
        </Item>
      </Grid>
    </Container>
  );
}

export default NossosValores;
