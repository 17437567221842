import React from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {
  Container,
  TitleContainer,
  MainTitle,
  Grid,
  Foto,
  CommentContainer,
  Comment,
  Message,
  Autor,
  Video,
} from "./styles";

import turma1 from "../../images/turma1.jpg";
import turma2 from "../../images/turma2.jpg";

function Depoimentos() {
  const sliderSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <Container>
      <TitleContainer>
        <MainTitle>Depoimentos</MainTitle>
      </TitleContainer>
      <Slider {...sliderSettings}>
        <CommentContainer>
          <Comment>
            <Message>
              Poderia afirmar que participar dos cursos da Martins treinamentos
              foi como um divisor de águas em minha vida profissional...
            </Message>
            <Autor>Edvan Santos (Ed soluções)</Autor>
          </Comment>
        </CommentContainer>
        <CommentContainer>
          <Comment>
            <Message>
              Dificilmente você encontrará uma outra equipe tão empenhada em
              instruir, qualificar e incentivar um profissional que sofre com a
              falta de oportunidades...
            </Message>
            <Autor>Jadson Araújo (Operador)</Autor>
          </Comment>
        </CommentContainer>
        <CommentContainer>
          <Comment>
            <Message>
              Mais que um aluno, amigo e colaborador da Martins treinamentos, eu
              me sinto um integrante de uma família, que luta dia-a-dia pelo
              próximo...
            </Message>
            <Autor>Edinailton Dias (Grupo Ludeso)</Autor>
          </Comment>
        </CommentContainer>
        <CommentContainer>
          <Comment>
            <Message>
              Mesmo com toda a triste realidade que vivemos em nosso país, ainda
              é possível sonhar com dias melhores e pessoas mais humanas...
              Parabéns Martins treinamentos!
            </Message>
            <Autor>Dermival Souza (AMP 2)</Autor>
          </Comment>
        </CommentContainer>
        <CommentContainer>
          <Comment>
            <Message>
              Quando fazemos um curso na Martins treinamentos, não ganhamos
              apenas uma qualificação profissional. Ganhamos amigos maravilhosos
              como Edson Martins!
            </Message>
            <Autor>Cicero (Operador)</Autor>
          </Comment>
        </CommentContainer>
      </Slider>

      <Grid>
        <Foto src={turma1} alt="Turma com certificados" />
        <Video
          src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fedson.martins.3517%2Fvideos%2F1545214662169256%2F&show_text=0&width=560"
          style={{ border: "none", overflow: "hidden" }}
          scrolling="no"
          frameborder="0"
          allowTransparency="true"
          allowFullScreen="false"
          title="Curso de Operador de Empilhadeira"
        />
        <Foto src={turma2} alt="Turma com certificados" />
      </Grid>
    </Container>
  );
}

export default Depoimentos;
