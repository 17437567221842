import styled from "styled-components";
import { AnchorLink } from "gatsby-plugin-anchor-links";

export const Container = styled(AnchorLink)`
  z-index: 2;
  background-color: #ca0425;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  border: solid 2px #a5001b;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-box-shadow: 10px 10px 5px -6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 5px -6px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 5px -6px rgba(0, 0, 0, 0.75);

  position: fixed;
  right: 20px;
  bottom: 20px;

  &:hover {
    filter: brightness(70%);
    cursor: pointer;
  }

  @media only screen and (max-width: 900px) {
    display: none;
  }
`;
