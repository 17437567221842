import React from "react";
import { FaStar } from "react-icons/fa";

import { Container, ImageContainer, Info, Title, Description } from "./styles";

import mapa from "../../images/mapa.jpg";

function SobreNos() {
  return (
    <Container>
      <ImageContainer>
        <img src={mapa} alt="Equipe" />
      </ImageContainer>
      <Info>
        <Title id="sobre">
          <FaStar size={40} color="#C90225" style={{ marginRight: "10px" }} />
          Sobre nós
        </Title>
        <Description>
          Nossa empresa presta serviços para diversas instituições, públicas e
          privadas, em Camaçari e em diversas cidades do Estado da Bahia.
          <br />
          <br />
          Com um formato inovador e com uma equipe qualificada, levamos para
          todos os nossos clientes e amigos uma solida base de conhecimentos
          técnicos, que qualificam o nosso serviço e evidenciam nossos objetivos
          institucionais.
          <br />
          <br />
          Além da prestação de serviço empresarial, realizamos serviços sociais
          em parceria com instituições não governamentais (ONGs), que atendem e
          contemplam famílias e comunidades carentes.
        </Description>
      </Info>
    </Container>
  );
}

export default SobreNos;
