import styled from "styled-components";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import marmore from "../../images/marmore.jpg";
const MEDIA = "@media only screen and (max-width: 900px)";

export const Container = styled.div`
  background-image: url(${marmore});
  width: 100%;
`;

export const TitleContainer = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${MEDIA} {
    height: 100px;
  }
`;

export const MainTitle = styled.h1`
  font-size: 60px;
  color: #c90225;

  ${MEDIA} {
    font-size: 35px;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  ${MEDIA} {
    grid-template-columns: 1fr;
  }
`;

export const Item = styled.div`
  background-color: white;
  width: 80%;
  margin: auto;
  text-align: center;
  -webkit-box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.75);

  ${MEDIA} {
    margin-top: 30px;
    width: 90%;
  }
`;

export const Foto = styled.img`
  width: 100%;
`;

export const Title = styled.h3`
  margin: 10px 0;

  ${MEDIA} {
    font-size: 17px;
    padding: 0 5px;
  }
`;

export const Description = styled.p`
  margin: 20px;

  ${MEDIA} {
    font-size: 14px;
  }
`;

export const BtnSection = styled.div`
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Button = styled(AnchorLink)`
  background-color: #c90225;
  color: white;
  font-size: 22px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #9e001a;
  }

  ${MEDIA} {
    font-size: 18px;
  }
`;
