import styled from "styled-components";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const MEDIA = "@media only screen and (max-width: 900px)";

export const Container = styled.div`
  background-color: #eaedf3;
  width: 100%;
`;

export const TitleContainer = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${MEDIA} {
    height: 100px;
  }
`;

export const MainTitle = styled.h1`
  font-size: 60px;
  color: #c90225;

  ${MEDIA} {
    font-size: 35px;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => props.columns}, 1fr);

  ${MEDIA} {
    grid-template-columns: 1fr;
  }
`;

export const BtnSection = styled.div`
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Button = styled(AnchorLink)`
  background-color: #c90225;
  color: white;
  font-size: 22px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #9e001a;
  }

  ${MEDIA} {
    font-size: 18px;
  }
`;
