import styled from "styled-components";

const MEDIA = "@media only screen and (max-width: 900px)";

export const Container = styled.div`
  display: flex;
  padding: 100px;
  width: 100%;

  ${MEDIA} {
    flex-direction: column;
    padding: 0;
    align-items: center;
    margin: 50px 0;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;

  img {
    max-width: 500px;
  }

  ${MEDIA} {
    img {
      max-width: 100%;
    }
  }
`;

export const Info = styled.div`
  width: 80%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-right: 15px;

  ${MEDIA} {
    margin: 0;
    margin-top: 20px;
  }
`;

export const Title = styled.p`
  font-size: 60px;
  color: #c90225;
  margin-bottom: 30px;

  ${MEDIA} {
    font-size: 40px;
  }
`;

export const Description = styled.p`
  font-size: 20px;

  ${MEDIA} {
    font-size: 16px;
  }
`;
