import styled from "styled-components";

import marmore from "../../images/marmore.jpg";

const MEDIA = "@media only screen and (max-width: 900px)";

export const Container = styled.div`
  background-size: cover;
  background-image: url(${marmore});
  width: 100%;
  padding-bottom: 100px;

  ${MEDIA} {
    padding-bottom: 20px;
  }
`;

export const TitleContainer = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MainTitle = styled.h1`
  font-size: 60px;
  color: #c90225;

  ${MEDIA} {
    font-size: 40px;
  }
`;

export const Grid = styled.div`
  margin-top: 60px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  ${MEDIA} {
    grid-template-columns: 1fr;
    grid-template-rows: 350px 210px 350px;
  }
`;

export const Item = styled.div`
  background-color: white;
  width: 80%;
  margin: auto;
  text-align: center;
  -webkit-box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.75);
`;

export const Foto = styled.img`
  width: 80%;
  margin: auto;

  ${MEDIA} {
    width: 100%;
    margin-bottom: 10px;
  }
`;

export const CommentContainer = styled.div``;

export const Comment = styled.div`
  display: flex;
  min-height: 400px;
  padding: 60px 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
`;

export const Message = styled.p`
  width: 400px;
  font-size: 22px;
  color: white;

  ${MEDIA} {
    font-size: 18px;
    width: 80%;
  }
`;

export const Autor = styled.p`
  margin-top: 30px;
  font-size: 22px;
  font-weight: bold;
  color: white;

  ${MEDIA} {
    font-size: 18px;
  }
`;

export const Video = styled.iframe`
  width: 560px;
  height: 322px;

  ${MEDIA} {
    width: 100%;
  }
`;
