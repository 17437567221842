import styled from "styled-components";

const MEDIA = "@media only screen and (max-width: 900px)";

export const SloganContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 220px;

  ${MEDIA} {
    height: 200px;
  }
`;

export const SloganTitle = styled.h1`
  font-size: 50px;

  ${MEDIA} {
    font-size: 25px;
    text-align: center;
  }
`;
