import React from "react";
import { AiOutlineArrowUp } from "react-icons/ai";

import { Container } from "./styles";

function Topo() {
  return (
    <Container to="/#inicio">
      <AiOutlineArrowUp color="#ffffff" size={40} />
    </Container>
  );
}

export default Topo;
