import React, { useState } from "react";
import { graphql, StaticQuery } from "gatsby";
import { GoBook } from "react-icons/go";

import Post from "../Post";
import {
  Container,
  TitleContainer,
  MainTitle,
  Grid,
  BtnSection,
  Button,
} from "./styles";

function BlogWidget() {
  const [posts, setPosts] = useState([]);

  return (
    <Container style={{ display: posts.length === 0 ? "none" : "block" }}>
      <TitleContainer>
        <MainTitle>Nosso Blog</MainTitle>
      </TitleContainer>
      <Grid columns={posts.length}>
        <StaticQuery
          query={postsQuery}
          render={data => {
            const edges = data.allMarkdownRemark.edges;
            setPosts(edges);

            return edges.map(({ node }) => (
              <Post
                title={node.frontmatter.title}
                date={node.frontmatter.date}
                body={node.excerpt}
                slug={node.fields.slug}
              />
            ));
          }}
        />
      </Grid>
      <BtnSection>
        <Button to="/blog">
          Acessar a página
          <GoBook color="white" size={25} style={{ marginLeft: "10px" }} />
        </Button>
      </BtnSection>
    </Container>
  );
}

const postsQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { order: DESC, fields: frontmatter___date }
      limit: 3
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD/MM/YYYY")
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`;

export default BlogWidget;
