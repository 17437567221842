import React from "react";
import { GoPlus } from "react-icons/go";

import {
  Container,
  TitleContainer,
  MainTitle,
  Grid,
  Item,
  Foto,
  Title,
  Description,
  BtnSection,
  Button,
} from "./styles";

import empilhadeira from "../../images/cursos/empilhadeira.jpg";
import socorros from "../../images/cursos/socorros.jpg";
import consultoria from "../../images/cursos/consultoria.jpg";

function NossoServico() {
  return (
    <Container>
      <TitleContainer>
        <MainTitle>Nossos Serviços</MainTitle>
      </TitleContainer>
      <Grid>
        <Item>
          <Foto src={socorros} alt="Curso de Primeiros Socorros" />
          <Title>Curso de Primeiros Socorros</Title>
          <Description>
            Em parceria com as organizações privadas, como a Associação de
            Bombeiros Civis de Catu, e algumas instituições públicas da Bahia,
            realizamos o Curso de Primeiro Socorros, em Camaçari, Salvador,
            Lauro de Freitas, Mata de São João, São Francisco do Conde, Catu e
            diversas outras cidades do Estado. Com aulas teóricas e práticas
            que, notoriamente, tem modificado a vida de todos os participantes.
          </Description>
        </Item>
        <Item>
          <Foto
            src={empilhadeira}
            alt="Curso de Formação para Operadores de Empilhadeira"
          />
          <Title>Curso de Formação para Operadores de Empilhadeira</Title>
          <Description>
            Em parceria com uma das maiores transportadoras da Bahia, (a
            EXPRESSO NEPOMUCENO), e com o Grupo LUDESO (Grupo de Luta pelo
            Desenvolvimento Social), nossa empresa já realizou mais de 30
            (trinta) turmas do Curso de Formação para Operadores de Empilhadeira
            e já qualificou mais de 300 (trezentos) profissionais nessa área.
          </Description>
        </Item>
        <Item>
          <Foto src={consultoria} alt="Consultoria e Planejamento" />
          <Title>Consultoria e Planejamento</Title>
          <Description>
            Um plano bem elaborado é um plano meio executado! Com essa
            filosofia, oferecemos o melhor da nossa equipe para atender os
            anseios dos nossos clientes e amigos na elaboração de seus planos de
            contingência e ação empresarial. Além de treinamentos especializados
            para que todos os colaboradores estejam sempre atualizados e
            capacitados para agir.
          </Description>
        </Item>
      </Grid>
      <BtnSection>
        <Button to="/servicos">
          Conheça todos os serviços
          <GoPlus color="white" size={25} style={{ marginLeft: "10px" }} />
        </Button>
      </BtnSection>
    </Container>
  );
}

export default NossoServico;
