import React from "react";

import { SloganContainer, SloganTitle } from "./styles";

function Slogan({ title }) {
  return (
    <SloganContainer>
      <SloganTitle>{title}</SloganTitle>
    </SloganContainer>
  );
}

export default Slogan;
