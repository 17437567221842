import styled from "styled-components";

const MEDIA = "@media only screen and (max-width: 900px)";

export const Container = styled.div`
  background-color: #eaedf3;
  width: 100%;
  padding-bottom: 60px;
`;

export const TitleContainer = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${MEDIA} {
    height: 100px;
    padding-top: 30px;
  }
`;

export const MainTitle = styled.h1`
  font-size: 60px;
  color: #c90225;

  ${MEDIA} {
    font-size: 40px;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  ${MEDIA} {
    grid-template-columns: 1fr;
  }
`;

export const Item = styled.div`
  background-color: white;
  width: 80%;
  margin: auto;
  padding-top: 10px;
  text-align: center;
  -webkit-box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.75);

  ${MEDIA} {
    margin-top: 30px;
    width: 90%;
  }
`;
export const Title = styled.h3`
  margin: 10px 0;
`;

export const Description = styled.p`
  margin: 20px;

  ${MEDIA} {
    font-size: 12px;
  }
`;
