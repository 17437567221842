import React from "react";

import {
  Container,
  ImageContainer,
  Info,
  Title,
  PhoneSvg,
  Description,
  Download,
  DownloadImage,
} from "./styles";

import phone from "../../images/phone.jpg";
import googleplay from "../../images/googleplay.jpg";

function NossoApp() {
  return (
    <Container>
      <Info>
        <Title id="app">
          <PhoneSvg color="#C90225" />
          Martins App
        </Title>
        <Description>
          Disponibilizamos para os nossos alunos, um aplicativo para que ele
          possa ver apostilas, fazer provas e até mesmo falar com o seu
          professor.
        </Description>
        <Download
          target="_blank"
          href="https://play.google.com/store/apps/details?id=br.com.cursosmartins.martins_treinamentos"
        >
          <DownloadImage src={googleplay} />
        </Download>
      </Info>
      <ImageContainer>
        <img src={phone} alt="Equipe" />
      </ImageContainer>
    </Container>
  );
}

export default NossoApp;
