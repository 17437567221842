import styled from "styled-components";
import { BsPhone } from "react-icons/bs";

const MEDIA = "@media only screen and (max-width: 900px)";

export const Container = styled.div`
  width: 100%;
  display: flex;
  padding: 100px;

  ${MEDIA} {
    padding: 0;
    align-items: center;
    margin-top: 40px;
    flex-direction: column-reverse;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;

  img {
    max-width: 450px;
  }

  ${MEDIA} {
    img {
      max-width: 60%;
    }
    width: 90%;
  }
`;

export const Info = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-right: 15px;

  ${MEDIA} {
    margin: 0;
    width: 90%;
    align-items: center;
    margin-bottom: 40px;
    font-size: 10px;
  }
`;

export const PhoneSvg = styled(BsPhone)`
  width: 45px;
  height: 45px;
  margin-right: 10px;

  ${MEDIA} {
    width: 25px;
    height: 25px;
  }
`;

export const Title = styled.p`
  font-size: 60px;
  color: #c90225;
  margin-bottom: 30px;

  ${MEDIA} {
    margin-top: 30px;
    width: 250px;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Description = styled.p`
  font-size: 20px;

  ${MEDIA} {
    font-size: 16px;
    text-align: center;
  }
`;

export const Download = styled.a`
  margin-top: 30px;
`;

export const DownloadImage = styled.img`
  width: 200px;

  ${MEDIA} {
    width: 180px;
  }
`;
