import styled from "styled-components";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import marmore from "../../images/marmore.jpg";

const MEDIA = "@media only screen and (max-width: 900px)";

export const HeaderBackground = styled.div`
  width: 100%;
  height: 500px;
  background-size: cover;
  background-image: url(${marmore});
  display: flex;
  align-items: center;
  justify-content: center;

  ${MEDIA} {
    height: 600px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: space-between;
  width: 1000px;

  ${MEDIA} {
    flex-direction: column-reverse;
    justify-content: center;
    width: 100%;
  }
`;

export const HeaderAction = styled.div`
  ${MEDIA} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
  }
`;

export const HeaderBrand = styled.img`
  width: 300px;

  ${MEDIA} {
    width: 200px;
  }
`;

export const HeaderDescription = styled.p`
  font-size: 30px;
  color: black;
  width: 500px;
  font-weight: bold;

  ${MEDIA} {
    width: 100%;
    font-size: 18px;
    text-align: center;
  }
`;

export const ActionLink = styled(AnchorLink)``;

export const ActionButton = styled.div`
  width: 300px;
  height: 60px;
  margin-top: 20px;
  border: none;
  background-color: #c90225;
  color: white;
  font-size: 23px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #9e001a;
  }
`;
