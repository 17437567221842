import React from "react";

import {
  HeaderBackground,
  HeaderBrand,
  HeaderContainer,
  HeaderDescription,
  HeaderAction,
  ActionButton,
  ActionLink,
} from "./styles";

import brand from "../../images/brand.png";

function HomeHeader() {
  return (
    <HeaderBackground>
      <HeaderContainer>
        <HeaderAction>
          <HeaderDescription id="inicio">
            Cursos Livres para Formação e Treinamento Profissional, com
            certificado reconhecido em todo o território nacional.
          </HeaderDescription>
          <ActionLink to="/servicos">
            <ActionButton>Veja nossos serviços!</ActionButton>
          </ActionLink>
        </HeaderAction>
        <HeaderBrand src={brand} alt="Martins Treinamentos e Consultoria" />
      </HeaderContainer>
    </HeaderBackground>
  );
}

export default HomeHeader;
