import React from "react";

import HomeHeader from "../components/HomeHeader";
import SobreNos from "../components/SobreNos";
import NossoServico from "../components/NossoServico";
import BlogWidget from "../components/BlogWidget";
import NossoApp from "../components/NossoApp";
import NossosValores from "../components/NossosValores";
import Depoimentos from "../components/Depoimentos";
import Contato from "../components/Contato";
import Slogan from "../components/Slogan";
import HomeContainer from "../components/HomeContainer";
import Topo from "../components/Topo";
import Layout from "../components/layout";
import SEO from "../components/seo";

function IndexPage() {
  return (
    <Layout>
      <SEO
        title="Início"
        description="Cursos Livres para Formação e Treinamento Profissional, com certificado reconhecido em todo o território nacional."
      />
      <HomeContainer>
        <HomeHeader />

        <SobreNos />

        <BlogWidget />

        <NossoServico />

        <NossoApp />

        <NossosValores />

        <Depoimentos />

        <Slogan title="Mais que uma empresa... Somos uma família!" />

        <Contato />

        <Topo />
      </HomeContainer>
    </Layout>
  );
}

export default IndexPage;
