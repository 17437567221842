import styled from "styled-components";

const MEDIA = "@media only screen and (max-width: 900px)";

export const Container = styled.div`
  margin-top: 100px;
  width: 100%;

  ${MEDIA} {
    margin-top: 0;
  }
`;
